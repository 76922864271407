import * as React from 'react'
import { Link } from 'gatsby'
import Layout2 from '../components/layout2'
import Seo2 from '../components/seo2'
import Bio from '../components/bio'

const AboutPage = () => {
    return (
            <Layout2 pageTitle="About Me">
        <Bio />
          </Layout2>
    )
}

export const Head = () => (
    <Seo2 title="About Me" />
)

export default AboutPage 